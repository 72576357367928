// React imports
import { useEffect } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useBlockBodyScroll = (shouldBlock: boolean) => {
  /** Use this when you want to show some kind of modal and block
   *  prevent the browser from scrolling the main site while the
   *  modal is open
   *
   **/
  useEffect(() => {
    if (shouldBlock) {
      const scrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
      document.body.style.top = `-${scrollY}px`
    }
    if (!shouldBlock) {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.paddingRight = '0px'
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [shouldBlock])
}

export default useBlockBodyScroll
