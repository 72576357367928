/**
 * NOTE: Check http://localhost:4242/theartling-remix/tagmanager/ for more
 * info!
 */

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export const pageview = () => {
  if (!window.dataLayer) {
    console.warn(
      'window.dataLayer is not defined. This could mean your GTM script has not loaded on the page yet.'
    )
    return
  }
  window.dataLayer.push({ event: 'Pageview' })
}

export const toggleFavourite = (product_id: string) => {
  if (!window.dataLayer) {
    console.warn(
      'window.dataLayer is not defined. This could mean your GTM script has not loaded on the page yet.'
    )
    return
  }
  window.dataLayer.push({ event: 'toggle_favourite', product_id })
}

export const findSimilar = (similar_to: string) => {
  if (!window.dataLayer) {
    console.warn(
      'window.dataLayer is not defined. This could mean your GTM script has not loaded on the page yet.'
    )
    return
  }
  window.dataLayer.push({ event: 'find_similar', similar_to })
}

export const showModal = (modal_name: string) => {
  if (!window.dataLayer) {
    console.warn(
      'window.dataLayer is not defined. This could mean your GTM script has not loaded on the page yet.'
    )
    return
  }
  window.dataLayer.push({ event: 'show_modal', modal_name })
}

export const viewImageSwiper = (display_index: number) => {
  if (!window.dataLayer) {
    console.warn(
      'window.dataLayer is not defined. This could mean your GTM script has not loaded on the page yet.'
    )
    return
  }
  window.dataLayer.push({ event: 'view_image_swiper', display_index })
}

export const viewInRoomDownloadImage = () => {
  if (!window.dataLayer) {
    console.warn(
      'window.dataLayer is not defined. This could mean your GTM script has not loaded on the page yet.'
    )
    return
  }
  window.dataLayer.push({ event: 'view_in_room_download_image' })
}

export const viewOnShelfDownloadImage = () => {
  if (!window.dataLayer) {
    console.warn(
      'window.dataLayer is not defined. This could mean your GTM script has not loaded on the page yet.'
    )
    return
  }
  window.dataLayer.push({ event: 'view_on_shelf_download_image' })
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const event = ({
  action,
  category,
  label,
  value,
}: Record<string, string>) => {
  if (!window.dataLayer) {
    console.warn(
      'window.dataLayer is not defined. This could mean your GTM script has not loaded on the page yet.'
    )
    return
  }
  return
  // TODO
  // window.gtag('event', action, {
  //   event_category: category,
  //   event_label: label,
  //   value: value,
  // })
}
